import * as React from 'react';
import { Link } from 'gatsby';
import Layout from '../components/Layout';

// import logo from '../img/logo.svg';
import iconArrowBack from '../img/iconarrow_back.svg';

const Impressum = () => (
  <Layout>
    <main className="impressum">
      <div className="page-indicator">Impressum</div>
      <div className="grain"></div>
      <Link className="back-button" to={`/`}>
        <img
          className="portfolio-detail-prev-arrow"
          src={iconArrowBack}
          alt="Back to Projects"
          style={{ width: '45px' }}
        />
        <p>BACK</p>
      </Link>
      <h1>Impressum</h1>
      <h3>According to § 5 TMG:</h3>
      <p>I-AM-STUDIO GmbH</p>
      <p>Managing Director: Bernhard Schluga</p>
      <p>Schoenleinstraße 11, 10967 Berlin</p>
      <p>+49 30 120 845 38</p>
      <p>info@i-am-studio.com</p>
      <p>www.i-am-studio.com</p>
      <p>Sales tax identification no. DE298018579</p>
      <p>HRB 164039</p>
      <p>Place of jurisdiction: Berlin</p>
      <p>Responsible for the content according to § 55 Abs. 2 RStV:</p>
      <p>I-Am-Studio GmbH</p>

      <h3>1. Validity of the General Terms and Conditions</h3>
      <p>
        1. By placing an order, the Customer accepts the following General Terms
        and Conditions. Any general terms and conditions of the Customer that
        differ from and conflict with our General Terms and Conditions shall not
        become part of the contract, even where we do not expressly object
        thereto, including in the event of our delivery or provision of
        services.
      </p>
      <p>
        2. In cases of permanent contractual relationships, such as framework
        agreements for ongoing consulting and agency services, these General
        Terms and Conditions shall apply to any and all individual orders and
        service requests during the order relationship, without the need for
        separate inclusion in the respective individual order.
      </p>
      <p>
        3. Terms and conditions of the Customer that are contrary to or deviate
        from these General Terms and Conditions shall only become effective,
        even where we are aware thereof, if they are expressly acknowledged by
        us in writing.
      </p>

      <h3>2. Conclusion of contracts, Obligations to perform</h3>
      <p>
        1. Our offers are subject to change without notice. Cost estimates drawn
        up by us are non- binding.
      </p>
      <p>
        2. Contracts and amendments of contracts with us shall only enter into
        force if and when we have accepted orders that we have received or have
        provided the services ordered by the Customer.
      </p>
      <p>
        3. We shall only provide such services that are expressly specified in
        our offers and/or cost estimates.
      </p>
      <p>
        4. The subject matter of each contract is the provision of the agreed
        service by us, but not any specific economic success the Customer hoped
        for or intended.
      </p>
      <p>
        5. We shall not owe the protection or registrability under copyright,
        design, competition, trademark and/or patent law of the delivered ideas,
        suggestions, proposals, concepts, designs, communication measures and
        other services.
      </p>
      <p>
        6. Unless expressly agreed otherwise, we shall not owe the examination
        of any conflicting rights of third parties when creating our services.
        We therefore shall not owe the legal admissibility and/or usability, in
        particular under competition, copyright, design, trademark and/or patent
        law, of the ideas, suggestions, proposals, concepts, drafts,
        communication measures and other services provided by us. In particular,
        we shall conduct design, patent or trademark searches only upon special,
        chargeable commissioning by the Customer. We shall, however, point out
        possible legal risks to the Customer where we become aware thereof
        during the performance of the order.
      </p>
      <p>
        7. Where we are commissioned to produce digital artwork, we shall
        deliver the files to the Customer or – upon the Customer’s request – to
        the printer. Any use or processing of the file at the supplier/printer
        for other purposes shall requires our consent.
      </p>
      <p>
        8. We shall be entitled to store raw materials and ancillaries provided
        to us or produced by us for the creation of the respective service, in
        particular manuscripts,artwork, film material, photographs and final
        artwork with reasonable care up to the expiry of the applicable
        limitation period for defects.
      </p>

      <h3>3. Copyright, Rights of use</h3>
      <p>
        1. Any work may only be used or exploited for the agreed or commissioned
        type of use and the agreed purpose to the agreed extent. Any other or
        additional use shall only be permitted with our consent and, where
        applicable, upon agreement of an additional usage fee. The granting of
        rights of use shall not require that the work enjoys legal protection,
        such as under the German Copyright Act, the Design Patent Act, the
        Patent Act or the Unfair Competition Act.
      </p>
      <p>
        2. Upon payment of the usage fee, the Customer shall acquire the right
        to use and exploit the work within the agreed scope. Where the Customer
        only pays a presentation fee, we shall retain any and all rights of use
        and ownership to the work submitted as part of the presentation.
      </p>
      <p>
        3. The designs, data sets, style sheets, templates, internet
        programming, application programming, software tools, work drawings,
        constructions, compositions, music tracks or sound elements created by
        us, including the copyright designation, may not be changed either in
        the original or in reproduction without our consent in text form, unless
        otherwise agreed in text form. Any imitation – including of parts or
        details – shall not be permitted.
      </p>
      <p>
        4. Any rights transferred to GEMA or another collecting society for
        administration shall remain unaffected by the transfer of the rights of
        use to the Customer. Any rights transferred to the collecting societies
        for administration shall be duly acquired separately in each case by the
        Customer from the responsible collecting society. The Customer shall
        ensure that the actual music use is brought to the attention of GEMA by
        submitting truthful music registrations without undue delay. Upon
        request, the Customer shall provide us with copies of such music
        registrations.
      </p>
      <p>
        5. Suggestions of the Customer or the Customer’s other cooperation shall
        have no influence on the amount of the remuneration. In particular, they
        shall not establish any joint copyright of the Customer, unless such has
        been expressly agreed in text form.
      </p>
      <p>
        6. The operating items used by us for the production of the contractual
        product, in particular data carriers, shall remain our property, even
        where they are invoiced separately, and shall not be supplied to the
        Customer. This shall also apply analogously to any and all other data
        stored in connection with the performance of the order.
      </p>
      <p>
        7. By placing the respective order, of which these General Terms and
        Conditions are an integral part, the Customer declares to be entitled to
        any and all necessary rights to the materials provided to us (slogans,
        logos, images, videos, texts, etc.). Where claims are asserted against
        us by third parties in or out of court as a result of the use of
        materials provided by the Customer, the Customer shall reimburse us for
        any costs incurred and hold us harmless and indemnify us from and
        against any and all damage incurred.
      </p>

      <h3>4. Customers’ duty to cooperate</h3>
      <p>
        1. The Customer shall support us in the performance of the service by
        providing the required number of competent employees, in particular by
        providing information, drafts, data material as well as hardware and
        software in a timely manner, where such this is necessary for the
        performance of the service by us.
      </p>
      <p>
        2. Where the Customer has to provide us with images, sound, text or
        other materials for the provision of services, such shall be transmitted
        to us in a common, directly usable, preferably digital, format. Where it
        is necessary to convert the material provided by the Customer into
        another format, any additional costs and expenses incurred shall be
        borne by the Customer. Such costs shall be communicated by us in
        advance. The Customer shall ensure that we are entitled to use the
        materials provided to us to the extent necessary for the performance of
        the contract.
      </p>
      <p>
        3. The Customer shall perform all acts of cooperation on its own
        responsibility, at its own risk and at its own expense. Data and data
        carriers provided by the Customer must be free of viruses, Trojans and
        other malware; otherwise, the Customer shall be obligated to compensate
        us for any resulting damage.
      </p>
      <p>
        4. The Customer must keep backup copies of any and all data provided to
        us.
      </p>

      <h3>5. Approvals, Print approvals</h3>
      <p>
        1. The Customer shall approve our drafts upon our request. Upon approval
        by the Customer of proofs, drafts, final designs, final layouts, texts,
        electronic media and concepts, which we provide to the Customer for
        checking/correction, the Customer shall assume responsibility for the
        correctness of words and images (including content).
      </p>
      <p>
        2. The Customer must give print approval prior to each print. The
        Customer shall check the conformity of the files supplied by us with
        respect to the results desired by the Customer on the basis of separated
        printouts and/or proofs prior to the print approval. The risk of any
        defects shall pass to the Customer upon approval for printing unless
        such are defects that could only be detected in the subsequent
        production process. The same shall apply in the event of uncontrolled
        print approval, forwarding or processing of files or templates by the
        Customer.
      </p>

      <h3>6. Subcontractors, External services</h3>
      <p>
        1. We shall be entitled to use third parties as subcontractors to meet
        our contractual obligations.
      </p>
      <p>
        2. We shall be authorized to order the external services necessary for
        the performance of the order on behalf and for the account of the
        Customer. In the event of an order value of up to EUR 3,000 net, such
        may also be done without prior consultation with the Customer, unless
        otherwise agreed in text form. In the event of orders exceeding a net
        value of goods or services of EUR 3,000, the order shall be placed after
        consultation with the Customer. Orders shall also be deemed to have been
        placed on behalf and for the account of the Customer where we are
        invoiced by the supplier (contractor). Upon the supplier’s request, the
        Customer shall confirm the placing of the order in text form. Where an
        order is placed in the name of and on behalf of the Customer, the
        special features customary in the industry, for example in the printing
        sector, shall be observed and the general terms and conditions of the
        suppliers, which we shall provide to the Customer upon request, shall
        regularly apply.
      </p>
      <p>
        3. Where contracts for external services are entered into on our behalf
        and for our account for the performance of the order, we shall charge
        the costs to the Customer. The Customer shall hold us harmless and
        indemnify us internally from and against any and all liabilities arising
        therefrom.
      </p>
      <p>
        4. Where taking over production services of any kind in our own name, we
        shall be entitled to make the necessary decisions and issue the
        corresponding instructions at our own discretion.
      </p>
      <p>
        5. For orders placed with third parties on behalf of and for the account
        of the Customer, we shall not assume any liability or responsibility
        towards the Customer for defects in the work, unless we are at fault in
        the selection of the third party. In such events, we shall merely act as
        an intermediary. Where we ourselves are the client of subcontractors, we
        hereby assign to the Customer any and all claims to which we are
        entitled for defects in the work, claims for damages and other claims
        arising from defective delivery, delayed delivery or non-delivery. The
        Customer shall be obligated to first attempt to enforce the assigned
        claims against the subcontractor prior to asserting a claim against us.
      </p>
      <h3>7. acceptance and fictitious acceptance</h3>
      <p>
        1. In the event of contracts for work and services, the Customer shall
        be obligated to accept the work. In the event of significant deviations
        from the work owed, we shall remedy such within a reasonable period and
        resubmit the work for acceptance.
      </p>
      <p>
        2. Where we notify the Customer of the completion of a work performance
        in text form and the Customer fails to report any more than
        insignificant defects within a period of four weeks after notification
        of completion, the work performance shall be deemed accepted. Acceptance
        shall be deemed to have taken place at the latest upon unconditional
        payment or use of the work.
      </p>
      <h3>
        8. Remuneration, Payment and default of payment, Retention of title
      </h3>
      <p>
        1. The remuneration to be paid by the Customer shall arise from the
        contract entered into with the Customer. Where no remuneration is
        specified for a service, our price lists effective at the time of the
        order shall apply. Additional expenses incurred due to the Customer’s
        requests for changes and additions or due to the Customer’s breach of
        the Customer’s duty to cooperate shall be charged as additional expenses
        according to the agreed hourly rates or, in the absence of such,
        according to the remuneration rates of our price lists effective at the
        time of the order.
      </p>
      <p>
        2. In the event of print orders, the quantity actually delivered shall
        be invoiced. No objections shall be possible for excess or missing
        quantities of up to 10%.
      </p>
      <p>
        3. We shall be entitled to demand reasonable payments on account: one
        third of the total remuneration when the order is placed and another
        third of the total remuneration when half of the work is completed.
      </p>
      <p>
        4. The Customer shall assume the travel expenses incurred in connection
        with the provision of the services owed under this contract. We shall
        agree the travel costs with the Customer in advance.
      </p>
      <p>
        5. The remuneration to which we are entitled shall be due within 14 days
        after invoicing without deduction.
      </p>
      <p>
        6. All prices are net prices and are subject to the applicable statutory
        value-added tax. Any customs duties, fees and other charges, such as the
        artists’ social security contribution shall be borne by the Customer,
        even where they are subsequently levied.
      </p>
      <p>
        7. We accept checks and bills of exchange only upon prior agreement and
        bills of exchange only subject to their discountability. All discount
        charges and other ancillary costs shall be borne by the Customer and
        shall be reimbursed to us without undue delay. Bills of exchange or
        check amounts shall only be credited after their countervalue has been
        made available to us without reservation.
      </p>
      <p>
        8. The Customer may pay by credit card or PayPal only upon prior
        agreement with us in text form.
      </p>
      <p>
        9. Drafts, data sets, style sheets, templates and other software tools
        as well as work drawings, together with the granting of the rights of
        use, shall constitute a single service, the remuneration for which,
        unless otherwise agreed in text form, is composed of: a) the design fee.
        b) the fee for the granting of rights of use (usage fee). c) the
        preparation or final artwork fee. Where no rights of use are granted,
        the remuneration for such shall not apply. Only rights of use shall be
        granted for drafts and compositions/final artwork, but no property
        rights shall be transferred. The claim to remuneration for the usage fee
        shall exist irrespective of whether or not the material protection
        requirements of the right granted are met.
      </p>
      <p>
        10. Conceptual and/or design proposals in the preparation to the
        conclusion of a contract shall be developed – irrespective of deviating
        provisions in text form in individual cases – against payment of the
        remuneration agreed with the Customer (presentation fee). Where no
        remuneration is agreed, the usual local prices shall apply.
      </p>
      <p>
        11. The services and deliveries provided by us shall remain our property
        until full payment of any and all claims arising from an order. The
        granting of rights of use and exploitation shall also be dependent on
        the full payment of our claims. Where we allow the Customer to use or
        tolerate the use of the service(s) rendered prior to full payment and
        where the Customer defaults on payment of the remuneration, the Customer
        undertakes to cease use of the service(s) and to refrain from their
        exploitation (reproduction, distribution, making available to the
        public, broadcasting, public reproduction, processing, modification,
        etc.) without undue delay at our request.
      </p>
      <p>
        12. Where the Customer fails to accept the design proposals presented by
        us, we shall retain the copyrights to the design proposals. The same
        shall apply to the ownership of the relevant templates.
      </p>
      <h3>9. Deadlines, Dates, Transfer of risk for deliveries</h3>
      <p>
        1. Deadlines and dates specified by us shall only be binding where they
        have been expressly agreed with our Customer as binding deadlines/dates
        in text form.
      </p>
      <p>
        2. Agreed delivery times can only be met where the Customer has met the
        obligations incumbent upon the Customer (for example, timely payment of
        an agreed down payment, full and complete provision of any documents to
        be made available in due time, timely fulfillment of the duties to
        cooperate, etc.). In the event of subsequent requests for changes or
        additions by the Customer, the delivery period shall be extended
        accordingly. Where we fail to meet delivery dates, the Customer shall
        set us a reasonable grace period in text form, which shall commence upon
        our receipt of the grace period notice. The Customer shall only be
        entitled to withdraw from the contract after the fruitless expiry of the
        grace period.
      </p>
      <p>
        3. A withdrawal of the Customer from the entire contract due to partial
        delay or partial impossibility shall only be permissible where the
        partial performance already rendered is demonstrably of no interest to
        the Customer.
      </p>
      <p>
        4. Even in the case of agreed deadlines and dates, we shall not be
        responsible for delays in delivery and performance due to force majeure.
        Force majeure shall be deemed to include, in particular, war, riots,
        pandemics, interventions by higher authorities, measures within the
        scope of industrial disputes, strikes or lockouts, shortages of raw
        materials or energy as well as unavoidable operational or transport
        disruptions such as disruptions in the external data network including
        house connection at network operators, internet access and/or service
        providers, power failures, fire, water ingress or weather conditions
        affecting transport. This shall also apply where the aforementioned
        conditions occur at our upstream suppliers or where we are not supplied
        by them through no fault of our own, despite corresponding contracts
        which would have covered the requirements arising from the agreement
        with the Customer. In such event, we shall be entitled to postpone the
        delivery or service for the duration of the hindrance plus a reasonable
        start-up period or to withdraw from the contract in whole or in part due
        to the part not yet fulfilled.
      </p>
      <p>
        5. We shall be entitled to make partial deliveries and render partial
        services at any time, provided that such may be reasonable expected from
        the Customer.
      </p>
      <p>
        6. The shipment of the work, services, products and templates shall be
        at the risk and for the account of the Customer. The risk shall pass to
        the Customer as soon as the shipment has been delivered or handed over
        to the person carrying out the transport. The transport risk shall also
        be borne by the Customer in the case of partial deliveries or in the
        case of returns. Any possible transport damage may only be claimed from
        the assigned transport company (post office, railroad, forwarding agent,
        etc.).
      </p>

      <h3>10. Defects, Claims due to defects, Limitation period</h3>
      <p>
        1. The files produced by us have been created with an optimal output
        quality on our output devices. Files may in general not be output on any
        device with the same quality. Depending on the type and calibration of
        the output device, there may be differences in quality. Colors and image
        impressions may change significantly, fonts may take on a different
        width, etc. In the event of color reproductions, minor deviations
        between proofs and the production print as well as within the production
        print up to a tolerance of +/- 15% of the full-tone density value are
        considered agreed. Proofs, wax prints, chromalines, colored laser prints
        and other simulations of the printed image are never binding in terms of
        color. Orders with such templates shall be processed according to
        company-standard printing standards.
      </p>
      <p>
        2. Complaints due to defects – irrespective of their nature – are to be
        made to us in text form within 5 working days after delivery of the work
        or notification/transmission/performance of a service. Thereafter, the
        work/service shall be deemed accepted as free of defects. We may reject
        complaints after such period, in particular in the event of an obvious
        defect. Until clarification, the defective goods may only be used after
        express release by us in text form.
      </p>
      <p>
        3. In the event of a justified complaint, we shall be entitled to
        rectification or replacement delivery at our discretion. Color-binding
        originals shall require the use of edition paper and edition machines.
        In any event, the Customer shall check the contractual conformity of the
        delivered goods as well as preliminary and intermediate products sent
        for correction without undue delay.
      </p>
      <p>
        4. The period for the limitation of claims due to any material defects
        or defects of title of works produced by us shall be one year. The
        period shall commence upon the acceptance of the work
      </p>

      <h3>11. Liability</h3>
      <p>
        1. We shall be liable without limitation in the event of injury to body,
        life and health as well as in the event of intent and gross negligence.
        In the event of a breach of material contractual obligations and in the
        event of impossibility, we shall also be liable in the event of slight
        negligence, but then limited to compensation for the foreseeable damage
        typical for the contract. Otherwise, we shall not be liable in the event
        of slight negligence. Material contractual obligations (cardinal
        obligations) are contractual obligations the fulfillment of which makes
        the proper performance of the contract possible in the first place and
        on the observance of which the Customer regularly relies and may rely.
        Typical, foreseeable damage is such damage which is subject to the
        protective purpose of the contractual provision violated in each case.
        The above limitations of liability shall also apply to our vicarious
        agents and assistants.
      </p>
      <p>
        2. Where the Customer makes corrections, in particular to the files or
        other work results supplied by us, or has them made by third parties,
        any liability for any material defects or defects of title on our part
        shall lapse, unless the Customer proves that such manipulations were
        without influence on any error.
      </p>
      <p>
        3. In the event of the uncontrolled release for printing, forwarding or
        processing of files or templates by the Customer, we shall furthermore
        not be liable for damage and quality defects that occur during further
        processing (in particular during printing), unless the errors could not
        have been detected even during a proper inspection of the files or
        templates.
      </p>
      <p>
        4. We shall not be liable for damage, theft or destruction of equipment
        of any kind provided to us by the Customer, unless the gross negligence
        in handling by us can be proven. In any event, the burden of proof shall
        lie with the Customer.
      </p>

      <h3>12. Retention, Set-off</h3>
      <p>
        1. The Customer may not assert any right of retention against us from
        other orders.
      </p>
      <p>
        2. The Customer shall only be allowed to offset claims if the Customer’s
        own claim is legally binding or recognized or if it entitles the
        Customer to refuse performance.
      </p>
      <p>
        3. Such restrictions shall not apply to additional costs for completion
        and/or costs for the removal of defects to which the Customer is
        entitled against us.
      </p>

      <h3>13. References, Free copies</h3>
      <p>
        1. We shall be entitled to attach a copyright designation/signature to
        the service results created by us in a suitable place (e.g., in the
        Legal Notice of a website), provided that the Customer’s presentation
        interest is not impaired thereby.
      </p>
      <p>
        2. We shall be entitled to use the concepts, content, strategies and
        designs created for the Customer for our own presentation and reference
        purposes – in particular in print and online media such as brochures and
        websites – after their publication by the Customer and to link them to
        the Customer’s website. Where the designs are linked to content provided
        by the Customer (e.g., images supplied by the Customer), the Customer
        shall grant us a corresponding simple right of use to present such
        content as well.
      </p>
      <p>
        3. Until revoked in writing by the Customer, we shall be entitled to
        refer to the existing business relationship with the Customer using the
        Customer’s name and company logo.
      </p>
      <p>
        4. The Customer shall provide us with up to ten perfect copies of all
        work reproduced and produced by us free of charge at our discretion.
      </p>

      <h3>14. Non-solicitation, Liquidated damages</h3>
      <p>
        1. In the performance of the projects by our employees, we trust that
        the Customer will not take advantage of the knowledge and insights of
        our employees from the performance of the contract by poaching employees
        from us. The Customer therefore undertakes not to poach any employees
        from us for the duration of the contractual relationship with us and for
        a period of 12 months after termination of the contractual relationship
        with us.
      </p>
      <p>
        2. In the event of any breach of the obligation under clause 14.1
        hereinabove, the Customer undertakes to pay to us liquidated damages in
        the amount of the last six months’ salary of the employee poached or
        employed by the Customer.
      </p>
      <p>
        3. Additional claims for damages shall not be excluded by the agreed
        liquidated damages; the liquidated damages shall, however, be offset
        against the claims for damages.
      </p>
      <h3>15. Final provisions</h3>
      <p>
        1. Place of performance for any and all performance obligations –
        including those of our Customer – is Berlin.
      </p>
      <p>
        2. Governing law shall be the law of the Federal Republic of Germany
        excluding the application of conflict of laws provisions and excluding
        the application of the United Nations Convention on Contracts for the
        International Sale of Goods (CISG).
      </p>
      <p>
        3. The Place of Jurisdiction, provided that Customer is a merchant, a
        legal person under public law or a special fund under public law, shall
        be Berlin, Germany. In case of jurisdiction of the Local Courts
        [Amtsgerichte], the Local Court [Amtsgericht] Tempelhof-Kreuzberg is
        responsible. Each contractual partner shall, however, also be entitled
        to take legal action against the other contractual partner at the other
        contractual partner’s general place of jurisdiction.
      </p>
      <p>
        4. Any amendments of or supplements to the contract, including these
        General Terms and Conditions, must be made in text form to be effective.
        This shall also apply to the cancellation of this text form requirement.
      </p>
      <p>
        5. Should one or more provisions of these General Terms and Conditions
        be or become ineffective, the effectiveness of the remaining provisions
        shall remain unaffected. In such event, the contracting parties shall
        replace the ineffective provisions by effective provisions most closely
        resembling the economic intent of the ineffective provisions.
      </p>
    </main>
  </Layout>
);

export default Impressum;
